import request from '@/lib/request.mjs';

import sortColltacts from '@/utils/sortColltacts.ts';
import { keysToCamel, keysToSnake } from '@/utils/string.ts';

import Contact from '@/models/Contact.mjs';

export async function one(contactId) {
  const data = await request('contact', { contactId });

  return new Contact(keysToCamel(data));
}

export async function all(forceRefresh = false) {
  let data = await request('contacts', { forceRefresh });

  // FIXME remove this when the groups feature in the webphone has been
  // deployed, or rather when this fake contact is properly handled.
  data = data.filter((obj) => obj.given_name !== '__UNLINKED_VOIP_ACCOUNTS__');

  return data.map((contactData) => new Contact(keysToCamel(contactData))).sort(sortColltacts);
}

export function create(contactData) {
  return new Promise((resolve, reject) => {
    request('contactCreate', {
      body: keysToSnake(contactData),
    })
      .then((data) => {
        resolve(new Contact(keysToCamel(data)));
      })
      .catch((err) => {
        reject(err);
      });
  });
}
